<template>
  <div>
    <v-layout class="mainfont" wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap pr-11 justify-end>
          <v-flex xl3 lg2 md3 pr-4 sm3 xs12 align-self-center>
            <v-autocomplete
              solo
              dense
              color="black"
              :items="category"
              v-model="categoryId"
              item-text="name"
              item-value="_id"
              item-color="#FF1313"
              hide-details
              clearable
              label="Category"
              class="custom-autocomplete rounded-lg mainfont"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                  >Category</span
                >
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xl3 lg2 md3 pr-4 sm3 xs12 align-self-center>
            <v-autocomplete
              solo
              dense
              color="black"
              :items="itemArray"
              v-model="status"
              item-text="name"
              item-color="#FF1313"
              hide-details
              clearable
              label=" Status"
              background-color="white"
              class="custom-autocomplete rounded-lg dropfont"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                >
                  Status</span
                >
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  class="rounded-lg dropfont"
                  solo
                  dense
                  outlined
                  readonly
                  v-model="fromDate"
                  v-bind="attrs"
                  background-color="white"
                  v-on="on"
                  @click:clear="from = null"
                  style="color: black"
                >
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      From Date</span
                    >
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                color="#13736f"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              max-width="290"
              elevation="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  label="To Date"
                  readonly
                  solo
                  dense
                  background-color="white"
                  color="#13736f"
                  class="rounded-lg dropfont"
                  outlined
                  v-model="toDate"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="from = null"
                >
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      To Date</span
                    >
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                color="#13736f"
                @change="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap v-if="list.length > 0">
          <v-flex
            xs11
            align-self-center
            pb-4
            v-for="(item, i) in list"
            :key="i"
          >
            <v-card
              style="border-radius: 15px"
              @click="goToDetailedView(item._id)"
            >
              <v-layout pt-4 pb-4 pl-4 wrap justify-center fill-height>
                <v-flex xs12 lg2 pl-5 align-self-center>
                  <v-avatar size="80px">
                    <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                  </v-avatar>
                </v-flex>
                <v-flex xs11 lg4 pr-8 align-self-center>
                  <v-layout wrap>
                    <v-flex xs12>
                      <span
                        class="mainfont"
                        style="
                          color: #13736f;
                          font-size: 16px;
                          font-weight: bolder;
                        "
                        >{{ item.categoryId.name }}</span
                      ></v-flex
                    >

                    <v-flex xs6>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 14px;
                          font-weight: lighter;
                        "
                        >Lot Number
                      </span>

                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 14px;
                          font-weight: lighter;
                        "
                      >
                        {{ item.lotNumber }}</span
                      >
                    </v-flex>

                    <v-flex xs6 v-if="item">
                      <span v-if="item.uid">
                        <v-icon color="black">mdi-account</v-icon>
                        <span
                          v-if="item.uid.traderId"
                          class="mainfont"
                          style="
                            color: black;
                            font-size: 15px;
                            font-weight: lighter;
                          "
                        >
                          <span v-if="item.uid.traderId.name">
                            {{ item.uid.traderId.name }}</span
                          >
                        </span></span
                      >
                    </v-flex>
                    <v-flex xs8 v-else>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 16px"
                        >seller
                      </span>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 15px;
                          font-weight: lighter;
                        "
                      >
                        {{ item.uid.farmerId.name }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 lg3 pl-2 align-self-center>
                  <v-layout wrap>
                    <v-flex xs8 v-if="item">
                      <span v-if="item.auctionId">
                        <span
                          class="mainfont"
                          style="
                            color: #13736f;
                            font-size: 16px;
                            font-weight: bolder;
                          "
                        >
                          Auction No

                          {{ item.auctionId.auctionNumber }}
                        </span>
                        <span v-if="item.auctionId.auctionNumber"></span>
                      </span>
                    </v-flex>

                    <v-flex xs6>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 16px"
                        ><v-icon color="black">mdi-calendar</v-icon>
                      </span>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 14px;
                          font-weight: lighter;
                        "
                      >
                        {{ formatDate(item.date) }}</span
                      >
                    </v-flex>

                    <v-flex xs6>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 16px"
                        ><v-icon color="black">mdi-clock</v-icon>
                      </span>
                      <span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 15px;
                          font-weight: lighter;
                        "
                      >
                        {{ formatTime(item.startingTime) }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex text-left pt-3 xs11 pl-10 lg3 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex
                      xs12
                      class="mainfont"
                      style="color: black; font-size: 14px"
                      >Starting Price

                      <span class="fontpop3">
                        &nbsp; Rs {{ item.startingPrice }}
                      </span>
                    </v-flex>
                    <v-flex
                      xs12
                      class="mainfont"
                      style="color: black; font-size: 14px"
                      >Total Weight

                      <span class="fontpop3">
                        &nbsp; {{ item.netWeight }}&nbsp;Kg
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 pb-4 text-center>
            <div>
              <v-pagination
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :length="pages"
                :total-visible="7"
                v-model="currentPage2"
                color="#13736f"
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
        <v-layout v-else justify-center>
          <v-flex text-center align-self-center>
            <span
              class="mainfont"
              style="color: black; font-size: 20px; font-weight: bold"
              >No Lot's Found !</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import { mdiChevronDown } from "@mdi/js";
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      test: null,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      itemArray: ["Pending", "Approved", "Rejected", "Won", "Withdrawn"],
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage2: parseInt(localStorage.getItem("currentPage2")) || 1,
      g: false,
      perPage: 4,
      totalData: 0,
      status: null,

      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      list: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      role: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      menu: false,
      menu1: false,
      menu2: false,
      departmentList: [],
      projectList: null,
      positionList: null,
      project: null,
      department: null,
      empList1: null,
      empList2: null,
      toDate: null,
      fromDate: null,
      empList3: null,
      search1: null,
      search2: null,
      search3: null,
      search11: null,
      search22: null,
      searchInput: null,
      search33: null,
      keysearch: null,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      ffproject: null,
      ffdepartmentList: null,
      fromDashboard2: false,

      categoryId: null,
      category: [],
      ffdepartment: null,
      ffpositionList: null,
      ffposition: null,
      moreFilter: false,
    };
  },
  components: {},

  watch: {
    status() {
      if (!this.fromDashboard2) {
        this.currentPage2 = parseInt(localStorage.getItem("currentPage2")) || 1;
      //  this.currentPage2 = 1;
   
      }
       else {
        this.currentPage2 = 1;
      }
      this.fromDashboard2 = false;
      console.log("status");
      this.getData();
    },
    currentPage2(newPage) {
      this.getData();
      localStorage.setItem("currentPage2", newPage);
    },
    fromDate() {
       this.currentPage2 = 1;
      this.getData();
    },
    toDate() {
       this.currentPage2 = 1;
      this.getData();
    },
    categoryId() {
      this.currentPage2 = 1;
      this.getData();
    },
  },
  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
    formattedDate() {
      const date = new Date(this.date);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
  created() {
    this.status = this.$route.query.status || "";
   
    // Check if the fromDashboard2 query parameter is present
    if (this.$route.query.fromDashboard2) {
      //  this.currentPage3 = 1; // Set currentPage3 to 1
      this.fromDashboard2 = true; // Set the flag
    } else {
      this.currentPage2 = parseInt(localStorage.getItem("currentPage2")) || 1;
    }
  },

  mounted() {
    this.getCategory();
    this.getData();
    this.currentPage2 = parseInt(localStorage.getItem("currentPage2")) || 1;
  },

  methods: {
    goToDetailedView(itemId) {
      this.$router.push({
        path: "/LotDetailedview",
        query: {
          id: itemId,
          currentPage2: this.currentPage2,
        },
      });
    },

    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    winStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios
        .get("/admin/lot/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            status: this.status,
            categoryId: this.categoryId,
            keyword: this.keysearch,
            page: this.currentPage2,
            fromDate: this.fromDate,
            toDate: this.toDate,
            limit: 10,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    uploadCoverImages(id) {
      this.flagg = false;
      this.appLoading = true;
      this.formDataCover.append("photo", this.coverImageFile);
      axios({
        method: "POST",
        url: "/employee/photo/upload/" + id,
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formDataCover = new FormData();
            this.msg = response.data.msg;
          } else {
            this.msg = "Can't Upload Profile Image.. Please Try Again Later";
            this.showSnackBar = true;
            this.formDataCover = new FormData();
          }
          this.getData();
          this.flagg = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Profile Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },

    edit() {
      this.editingitem.id = this.editingitem._id;
      this.editingitem.positionid = this.test;
      this.editingitem.departmentid = this.department;
      this.editingitem.role = this.role;
      this.editingitem.projectid = this.editingitem.projects;
      console.log("this.editingitem NEW== ", this.editingitem);
      axios({
        url: "/employee/edit/v2",
        method: "POST",
        data: this.editingitem,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;
          this.appLoading = false;
          if (response.data.status) {
            if (this.coverImageFile) {
              this.uploadCoverImages(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
<style >
/* Custom style for the text field */
.custom-text-field .v-input__control {
  background-color: white;
  /* Add any other custom styles for the text field here */
}

/* Custom style for the date picker */
.custom-date-picker .v-date-picker-table {
  background-color: white;
  /* Add any other custom styles for the date picker here */
}
.mainfont {
  font-family: mainfont;
  font-size: 17px;
  color: #000000;
  text-transform: none;
}
.custom-text-field .v-input__control {
  background-color: white;
  color: black;
}

/* Custom style for the label text */
.custom-label-text {
  color: black;
}
.custom-autocomplete .v-input__control {
  background-color: white;
  color: black;
}

/* Custom style for the label text */
.custom-label-text {
  color: black;
}
.custom-autocomplete .v-input__control {
  background-color: white;
  color: black;
}

/* Custom style for the label text */
.custom-label-text {
  color: black;
}
.v-pagination .v-pagination__item:not(.v-pagination__item--active) {
  color: #005f32; /* Replace with your desired color */
}

.v-pagination .v-pagination__item--active {
  background-color: #005f32; /* Replace with your desired color */
  color: white; /* Replace with your desired text color for active page */
}
/* Custom style for the text field */
.custom-text-field .v-input__control {
  background-color: white;
  /* Add any other custom styles for the text field here */
}

/* Custom style for the date picker */
.custom-date-picker .v-date-picker-table {
  background-color: white;
  /* Add any other custom styles for the date picker here */
}

/* Custom style for the menu */
.custom-menu {
  z-index: 999; /* Set a higher z-index value to ensure the menu appears on top */
}
.no-box-shadow .v-menu__content {
  box-shadow: none !important;
}
</style>
